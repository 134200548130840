import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Divider from '@mui/joy/Divider';

function Sidebar() {
  const [showAnnouncement, setShowAnnouncement] = useState(true);

  return (
    <Box
      component="nav"
      className="Sidebar"
      sx={{
        flexShrink: 0,
        width: 250,
        bgcolor: '#f3e8ff',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 40px)',
        m: 2,
        borderRadius: '8px',
        border: '2px solid black',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 8,
          left: 8,
          right: -8,
          bottom: -8,
          backgroundColor: 'black',
          borderRadius: '8px',
          zIndex: -1,
        },
      }}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
        <List sx={{ '& .MuiListItem-root': { mb: 2 } }}>
          <ListItem>
            <Typography level="h5" sx={{ fontWeight: 'bold', mb: 2 }}>8motions</Typography>
          </ListItem>
          <ListItem>
            <ListItemButton component={Link} to="/">
              <AutoAwesomeIcon sx={{ fontSize: 24, mr: 2 }} aria-hidden="true" />
              <ListItemContent>Create Content</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component={Link} to="/my-articles">
              <ArticleRoundedIcon sx={{ mr: 2 }} />
              <ListItemContent>My Articles</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component={Link} to="/settings">
              <SettingsIcon sx={{ mr: 2 }} />
              <ListItemContent>Settings</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
        
        {showAnnouncement && (
          <Card
            variant="outlined"
            sx={{
              m: 2,
              mt: 'auto',
              mb: 2,
              bgcolor: 'background.level1',
              borderColor: 'divider',
            }}
          >
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              sx={{
                position: 'absolute',
                top: '0.25rem',
                right: '0.25rem',
                opacity: 0.6,
                padding: '8px',  // Increase padding to expand hit area
                '&:hover': {
                  opacity: 1,
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',  // Light background on hover for better visibility
                },
              }}
              aria-label="Close announcement"
              onClick={() => setShowAnnouncement(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            
            <CardContent sx={{ pt: 3 }}>
              <Typography level="title-sm" fontWeight="bold" sx={{ mb: 1 }}>
                <a href="https://8motions.com/update/" target="_blank" rel="noopener noreferrer">
                  Latest update 4 Sep, 2024
                </a>
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography level="body-sm">
                Content quality is improved. It will automatically retry if "Failed" appears in the status. Tasks should finish within 15 minutes; resubmit if it takes longer.
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
      
      <Divider sx={{ my: 2 }} />
      <List>
        <ListItem>
          <ListItemButton component={Link} to="/account">
            <AccountCircleRoundedIcon sx={{ mr: 2 }} />
            <ListItemContent>Account</ListItemContent>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

export default Sidebar;